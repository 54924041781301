import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dimensions`}</h1>
    <ul>
      <li parentName="ul">{`In Polis, we generally think of the `}<a parentName="li" {...{
          "href": "/dimensionality",
          "title": "dimensionality"
        }}>{`dimensionality`}</a>{` of the `}<a parentName="li" {...{
          "href": "/opinion-space",
          "title": "opinion space"
        }}>{`opinion space`}</a>{` as being defined by the number of `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{``}</li>
      <li parentName="ul">{`A single dimension in the `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` `}<a parentName="li" {...{
          "href": "/opinion-space",
          "title": "opinion space"
        }}>{`opinion space`}</a>{` is a comment`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      